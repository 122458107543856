import React from 'react';

const Motivation = ({ className }) => {
  return (
    <svg viewBox="0 0 500.696 500.696" className={className}>
      <path
        fill="currentColor"
        d="m441.664 198.188-44.778-51.303c-2.474-2.834-6.049-4.459-9.81-4.459-3.762 0-7.337 1.625-9.811 4.459l-44.779 51.303c-3.4 3.896-4.185 9.241-2.044 13.95 2.14 4.709 6.683 7.634 11.854 7.634h14.281v50.557c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-52.536c0-7.18-5.841-13.021-13.021-13.021h-11.908l40.426-46.315 40.426 46.315h-11.907c-7.18 0-13.021 5.841-13.021 13.021v52.536c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-50.557h14.281c5.172 0 9.715-2.925 11.854-7.634 2.141-4.708 1.358-10.054-2.043-13.95z"
      />
      <path
        fill="currentColor"
        d="m380.462 321.592c0-17.653-14.361-32.015-32.015-32.015h-8.536c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h8.536c9.382 0 17.015 7.633 17.015 17.015 0 9.383-7.633 17.016-17.015 17.016-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5c9.382 0 17.015 7.632 17.015 17.014 0 9.383-7.633 17.016-17.015 17.016-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5c9.382 0 17.015 7.632 17.015 17.014 0 9.383-7.633 17.016-17.015 17.016-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5c9.382 0 17.015 7.633 17.015 17.015s-7.633 17.015-17.015 17.015h-116.458c-20.995 0-41.447-5.972-59.15-17.274l-1.036-.66c-1.205-.768-2.603-1.175-4.03-1.175h-21.49c.093-1.037.149-2.085.149-3.146v-24.712c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v24.712c0 10.928-8.891 19.818-19.819 19.818h-48.316c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h48.315c12.555 0 23.577-6.681 29.704-16.672h24.275c19.924 12.505 42.86 19.109 66.397 19.109h116.458c17.653 0 32.015-14.361 32.015-32.015 0-9.828-4.452-18.638-11.446-24.515 6.994-5.878 11.446-14.687 11.446-24.516 0-9.828-4.452-18.637-11.445-24.514 6.993-5.878 11.445-14.687 11.445-24.516 0-9.828-4.452-18.637-11.445-24.514 6.994-5.878 11.446-14.687 11.446-24.516z"
      />
      <path
        fill="currentColor"
        d="m80.167 127.045h55.168v33.461c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-40.961c0-4.143-3.358-7.5-7.5-7.5h-56.774l105.047-95.555c2.152-1.96 5.4-1.96 7.555 0l105.048 95.555h-56.774c-4.143 0-7.5 3.357-7.5 7.5v43.637c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-36.137h55.168c5.297 0 9.954-3.192 11.864-8.132s.613-10.435-3.305-13.998l-109.408-99.52c-7.908-7.193-19.834-7.193-27.742 0l-109.407 99.52c-3.918 3.564-5.215 9.059-3.305 13.999 1.911 4.939 6.568 8.131 11.865 8.131z"
      />
      <path
        fill="currentColor"
        d="m142.835 184.969c-4.142 0-7.5 3.357-7.5 7.5v51.639c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-51.639c0-4.142-3.358-7.5-7.5-7.5z"
      />
      <path
        fill="currentColor"
        d="m63.297 297.991h48.315c10.928 0 19.819 8.891 19.819 19.818v88.957c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5c0 0-.053-90.967-.138-91.956h19.614c1.794 0 3.528-.643 4.889-1.812l62.76-53.939c8.66-7.442 14.884-17.185 17.998-28.17l7.119-25.114c.969-3.418 4.127-5.805 7.679-5.805 3.251 0 6.149 1.943 7.385 4.953l.75 1.827c5.041 12.288 7.597 25.253 7.597 38.534 0 8.552-1.084 17.088-3.221 25.37l-5.054 19.581c-.894 3.462-.15 7.069 2.039 9.896s5.496 4.447 9.071 4.447h23.028c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-18.478l3.916-15.176c2.453-9.506 3.697-19.303 3.697-29.118 0-15.243-2.934-30.124-8.72-44.229l-.751-1.827c-3.553-8.661-11.898-14.258-21.261-14.258-10.228 0-19.32 6.873-22.11 16.713l-7.119 25.115c-2.309 8.145-6.923 15.367-13.344 20.886l-60.651 52.127h-21.72c-6.108-10.074-17.179-16.819-29.795-16.819h-48.314c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.499 7.5 7.499z"
      />
    </svg>
  );
};

export default Motivation;
