import React from 'react';

const Diversity = ({ className }) => {
  return (
    <svg
      enableBackground="new 0 0 512.002 512.002"
      viewBox="0 0 512.002 512.002"
      className={className}>
      <path
        fill="currentColor"
        d="m379.144 332.653c-.161.043-.322.091-.48.142l-96.61 31.161c-2.491-13.547-14.32-26.727-34.127-27.528l-22.446-.908c-5.506-.245-10.173 4.069-10.396 9.587-.223 5.519 4.069 10.173 9.588 10.396l22.446.908c10.519.426 15.372 6.945 15.431 12.822.015 1.474-.251 2.83-.777 4.054-.167.297-.319.603-.456.915-2.097 3.681-6.783 5.976-13.404 6.376l-73.874 4.464c-5.513.333-9.711 5.072-9.378 10.585.332 5.513 5.042 9.708 10.585 9.379l73.874-4.464c13.454-.813 22.603-6.519 27.882-13.941l107.559-34.693c11.118-2.949 30.244-5.809 35.365 7.396.437 1.291-.512 2.532-2.819 3.688l-133.546 64.204c-22.646 8.186-72.786 11.542-113.125 14.244-17.861 1.196-34.731 2.326-47.489 3.738-.929.103-1.838.335-2.702.69l-100.245 41.21v-84.038h40.655c5.522 0 10-4.477 10-10 0-11.621 4.836-22.929 13.268-31.027 7.865-7.553 17.928-11.478 28.388-11.058l22.439.908c5.504.242 10.173-4.069 10.396-9.587s-4.069-10.173-9.588-10.396l-22.439-.908c-15.937-.652-31.221 5.256-43.05 16.616-9.902 9.51-16.416 22.06-18.602 35.452h-41.467c-5.522 0-10 4.477-10 10v108.961c0 3.335 1.663 6.451 4.434 8.308 1.671 1.12 3.612 1.692 5.567 1.692 1.286 0 2.577-.248 3.802-.751l112.731-46.344c12.174-1.297 28.251-2.374 45.234-3.511 44.06-2.95 93.997-6.294 119.15-15.596.294-.109.582-.231.864-.367l134.053-64.447c.038-.019.077-.037.115-.056 13.718-6.813 16.16-19.725 12.625-28.839-7.894-20.359-29.556-27.445-59.431-19.437z"
      />
      <path
        fill="currentColor"
        d="m507.568 1.692c-2.769-1.855-6.281-2.209-9.369-.941l-112.732 46.344c-12.174 1.297-28.251 2.374-45.234 3.511-44.06 2.95-93.997 6.294-119.15 15.596-.293.109-.582.231-.864.367l-134.054 64.446c-.038.019-.076.037-.114.056-13.719 6.812-16.161 19.725-12.626 28.838 5.766 14.871 18.875 22.66 37.164 22.66 6.753 0 14.212-1.062 22.268-3.221.161-.043.322-.091.48-.142l96.61-31.161c2.492 13.547 14.32 26.727 34.127 27.528l134.808 5.456c15.932.651 31.221-5.257 43.05-16.616 9.902-9.51 16.416-22.06 18.602-35.452h41.468c5.522 0 10-4.477 10-10v-108.961c-.001-3.335-1.664-6.451-4.434-8.308zm-15.567 107.269h-40.655c-5.522 0-10 4.477-10 10 0 11.621-4.836 22.929-13.268 31.027-7.865 7.553-17.932 11.477-28.388 11.058l-134.808-5.456c-10.519-.426-15.372-6.945-15.431-12.822-.015-1.471.251-2.826.775-4.049.169-.3.323-.609.461-.926 2.099-3.678 6.785-5.971 13.402-6.371l73.874-4.464c5.513-.333 9.712-5.072 9.378-10.585-.332-5.513-5.07-9.712-10.585-9.379l-73.873 4.464c-13.455.813-22.604 6.519-27.883 13.941l-107.56 34.693c-11.117 2.949-30.243 5.808-35.364-7.396-.438-1.291.511-2.532 2.818-3.688l133.547-64.204c22.646-8.186 72.786-11.542 113.125-14.244 17.861-1.196 34.731-2.326 47.489-3.738.929-.103 1.838-.335 2.702-.69l100.241-41.209v84.038z"
      />
      <path
        fill="currentColor"
        d="m253.419 322.506c.846.226 1.714.339 2.581.339s1.735-.113 2.58-.338c19.591-5.233 62.503-40.058 72.095-75.91 4.65-17.384.815-32.847-11.092-44.716-17.041-16.986-44.77-16.986-61.811 0-1.688 1.682-3.249.295-3.545 0-17.04-16.988-44.77-16.986-61.81 0-11.907 11.87-15.742 27.332-11.092 44.716 9.592 35.851 52.505 70.676 72.094 75.909zm-46.882-106.461c4.629-4.614 10.706-6.92 16.786-6.92 6.078 0 12.158 2.307 16.785 6.919 8.912 8.883 22.873 8.883 31.783 0 9.257-9.226 24.315-9.227 33.572 0 6.83 6.808 8.702 14.874 5.891 25.382-7.109 26.575-40.617 54.705-55.354 60.854-14.737-6.149-48.244-34.279-55.354-60.854-2.811-10.507-.939-18.573 5.891-25.381z"
      />
      <path
        fill="currentColor"
        d="m170.116 343.692c0 5.523 4.48 10 10.003 10s10-4.477 10-10-4.478-10-10-10h-.007c-5.523 0-9.996 4.477-9.996 10z"
      />
    </svg>
  );
};

export default Diversity;
