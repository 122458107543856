import React from 'react';

const Work = ({ className }) => {
  return (
    <svg viewBox="0 0 512 512" className={className}>
      <path
        fill="currentColor"
        d="M426.843,304.445c18.31-12.943,29.434-34.135,29.434-56.822v-44.311c0-1.082-0.177-2.121-0.494-3.096     c-3.593-35.638-33.744-63.548-70.288-63.548c-38.689,0-70.198,31.286-70.628,69.901c-0.021,0.263-0.04,0.527-0.04,0.796v40.258     c0,22.71,11.147,43.921,29.49,56.861c-17.872,6.19-34.26,16.337-47.813,29.747l-6.287-28.292     c-2.215-9.972-10.897-16.937-21.113-16.937H75.633c-6.595,0-12.744,2.95-16.871,8.095c-4.127,5.145-5.673,11.787-4.242,18.225     l30.367,136.651H10c-5.523,0-10,4.478-10,10v40c0,5.522,4.477,10,10,10h492c5.523,0,10-4.478,10-10v-40v-38.009     C512,368.693,476.352,321.598,426.843,304.445z M385.495,156.668c21.778,0,40.386,13.83,47.527,33.176     c-0.034,0.033-0.071,0.062-0.104,0.096c-12.413,12.603-29.677,19.831-47.366,19.831h-50.726v-2.009     c0.005-0.133,0.02-0.263,0.02-0.396C334.846,179.411,357.567,156.668,385.495,156.668z M334.826,247.623V229.77h50.726     c18.21,0,36.062-5.908,50.726-16.492v34.344c0,17.823-9.635,34.352-25.153,43.14c-15.753,8.942-35.331,8.955-51.094,0.028     c-0.019-0.011-0.061-0.034-0.098-0.055C344.445,281.947,334.826,265.431,334.826,247.623z M385.552,317.515     c58.696,0,106.448,47.753,106.448,106.448v28.009H322.668l-20.833-93.749C321.953,332.665,352.945,317.515,385.552,317.515z      M82.492,349.002h105.701c5.523,0,10-4.478,10-10s-4.477-10-10-10H78.048l-4.004-18.019c-0.14-0.631,0.097-1.094,0.319-1.372     c0.223-0.278,0.625-0.609,1.27-0.609h193.471c0.769,0,1.422,0.524,1.589,1.274l31.488,141.696H105.374L82.492,349.002z      M492,491.972H20v-20h77.353h217.294H492V491.972z"
      />
      <path
        fill="currentColor"
        d="M228.19,329.003c-2.63,0-5.21,1.069-7.07,2.93c-1.86,1.859-2.93,4.439-2.93,7.07     c0,2.63,1.07,5.21,2.93,7.069c1.86,1.86,4.44,2.931,7.07,2.931s5.21-1.07,7.08-2.931c1.86-1.859,2.92-4.439,2.92-7.069     c0-2.631-1.06-5.211-2.92-7.07C233.4,330.072,230.83,329.003,228.19,329.003z"
      />
      <path
        fill="currentColor"
        d="M128,255.972c34.19,0,66.334-13.314,90.51-37.49c38.094-38.094,48.258-96.412,25.292-145.117     c-2.355-4.996-8.315-7.135-13.31-4.78c-4.996,2.355-7.136,8.314-4.78,13.31c19.377,41.095,10.799,90.302-21.345,122.445     c-20.398,20.398-47.519,31.633-76.367,31.633s-55.969-11.234-76.367-31.633c-42.109-42.109-42.109-110.625,0-152.734     c31.932-31.934,80.899-40.625,121.845-21.626c5.009,2.323,10.955,0.147,13.28-4.862c2.324-5.01,0.147-10.956-4.862-13.28     C133.367-10.678,75.334-0.381,37.49,37.462c-49.907,49.907-49.907,131.112,0,181.02C61.667,242.658,93.81,255.972,128,255.972z"
      />
      <path
        fill="currentColor"
        d="M204.562,138.068h7.263c5.523,0,10-4.478,10-10c0-5.522-4.477-10-10-10h-7.263c-5.523,0-10,4.478-10,10     C194.562,133.59,199.039,138.068,204.562,138.068z"
      />
      <path
        fill="currentColor"
        d="M44.175,117.877c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h7.263c5.523,0,10-4.478,10-10     c0-5.522-4.477-10-10-10H44.175z"
      />
      <path
        fill="currentColor"
        d="M117.904,204.534v7.264c0,5.522,4.477,10,10,10s10-4.478,10-10v-7.264c0-5.522-4.477-10-10-10     S117.904,199.011,117.904,204.534z"
      />
      <path
        fill="currentColor"
        d="M151.999,160.972c2.631,0,5.257-1.032,7.221-3.081c3.821-3.987,3.687-10.317-0.301-14.139l-20.914-20.042     l0.09-71.956c0.007-5.523-4.464-10.006-9.987-10.013c-0.004,0-0.009,0-0.013,0c-5.517,0-9.993,4.469-10,9.987L118,127.96     c-0.003,2.73,1.11,5.344,3.081,7.232l24,23C147.019,160.049,149.511,160.972,151.999,160.972z"
      />
      <path
        fill="currentColor"
        d="M211.44,54.532c2.63,0,5.21-1.069,7.07-2.93s2.93-4.44,2.93-7.07s-1.07-5.21-2.93-7.069     c-1.86-1.86-4.44-2.931-7.07-2.931s-5.21,1.07-7.07,2.931c-1.86,1.859-2.93,4.439-2.93,7.069s1.07,5.21,2.93,7.07     C206.23,53.462,208.81,54.532,211.44,54.532z"
      />
    </svg>
  );
};

export default Work;
