import { Link } from 'gatsby';
import tw from 'twin.macro';

export const Testimonial = tw.p`text-gray-700 text-lg mb-4`;

export const Container = tw.section`w-full bg-white py-8 md:py-16 mx-auto`;

export const Title = tw.h2`font-display w-full px-8 md:px-8 mb-8 md:mb-12 text-center text-2xl lg:text-4xl font-bold leading-tight text-gray-800`;

export const LinkTo = tw(
  Link
)`font-display font-bold text-base tracking-wide py-3 px-8 rounded text-white bg-gray-700 hover:bg-primary-800 cursor-pointer`;

export const CTAContainer = tw.div`w-full mx-auto text-center bg-primaryLight`;

export const CTAHeader = tw.h2`block font-display w-full mb-4 md:text-xl lg:text-3xl font-bold leading-tight text-center tracking-wide`;

export const CTASubTitle = tw.h3`block w-full font-medium lg:text-base leading-tight text-center mb-8 tracking-wide sm:text-2xl`;

export const CTAWrapper = tw.div`mx-auto bg-primaryLight overflow-hidden`;

export const CTAContent = tw.div`mb-12 md:mb-0 md:w-[50%]`;
