import React from 'react';

const Sapling = ({ className }) => {
  return (
    <svg viewBox="0 0 512 512" className={className}>
      <path
        fill="currentColor"
        d="m71.858 408.115 48.142 24.066v53.819c0 5.523 4.478 10 10 10h106c5.522 0 10-4.477 10-10v-130.55c0-30.637-22.983-56.045-53.391-59.093-.118-.033-42.3-6.619-61.87-26.189l-22.458-22.448c-7.556-7.557-17.6-11.72-28.281-11.72v-80c0-22.056-17.944-40-40-40s-40 17.944-40 40v135.84c0 24.158 6.714 47.791 19.415 68.342 12.7 20.551 30.835 37.126 52.443 47.933zm-51.858-252.115c0-11.028 8.972-20 20-20s20 8.972 20 20v85.361c-12.196 7.052-20 20.2-20 34.639 0 10.68 4.162 20.723 11.719 28.28l51.21 51.22c3.903 3.905 10.236 3.907 14.142.001 3.905-3.905 3.906-10.236.001-14.142l-51.21-51.221c-3.78-3.779-5.862-8.8-5.862-14.138 0-8.464 5.357-16.044 13.332-18.861.003-.001.006-.003.009-.004h.001c6.896-2.444 15.077-.994 20.799 4.728l22.458 22.449c23.186 23.185 73.843 31.91 73.944 31.938 20.214 2.026 35.457 18.879 35.457 39.2v120.55h-86v-50c0-3.788-2.141-7.251-5.528-8.944l-53.669-26.83c-37.504-18.756-60.803-56.455-60.803-98.386z"
      />
      <path
        fill="currentColor"
        d="m246 246c0 5.523 4.478 10 10 10s10-4.477 10-10v-30h38.28c56.089 0 101.72-45.631 101.72-101.72v-48.28c0-4.044-2.437-7.691-6.173-9.239-3.737-1.548-8.038-.692-10.898 2.168-11.008 11.009-25.643 17.071-41.209 17.071-33.445 0-63.169 16.227-81.72 41.219v-91.219c0-5.523-4.478-10-10-10s-10 4.477-10 10v51.22c-18.551-24.992-48.275-41.22-81.72-41.22-15.566 0-30.201-6.062-41.209-17.071-2.859-2.86-7.161-3.717-10.898-2.168-3.736 1.549-6.173 5.195-6.173 9.239v48.28c0 56.089 45.631 101.72 101.72 101.72h38.28zm101.72-150c13.625 0 26.72-3.458 38.28-9.959v28.239c0 45.061-36.659 81.72-81.72 81.72h-18.28l56-42c4.418-3.313 5.313-9.582 2-14s-9.581-5.315-14-2l-64 48v-8.28c0-45.06 36.659-81.72 81.72-81.72zm-221.72-21.72v-28.239c11.561 6.501 24.655 9.959 38.28 9.959 45.061 0 81.72 36.66 81.72 81.72v8.28l-64-48c-4.418-3.313-10.686-2.418-14 2-3.313 4.418-2.418 10.687 2 14l56 42h-18.28c-45.061 0-81.72-36.659-81.72-81.72z"
      />
      <circle fill="currentColor" cx="459" cy="384" r="10" />
      <path
        fill="currentColor"
        d="m472 116c-22.056 0-40 17.944-40 40v80c-10.681 0-20.725 4.162-28.279 11.717l-22.462 22.452c-19.137 19.139-61.695 26.152-61.796 26.181-30.48 3.056-53.463 28.463-53.463 59.101v130.549c0 5.523 4.478 10 10 10h106c5.522 0 10-4.477 10-10v-53.819l32.73-16.362c4.939-2.47 6.942-8.476 4.473-13.417-2.469-4.939-8.473-6.942-13.416-4.473l-38.259 19.125c-3.387 1.696-5.528 5.158-5.528 8.946v50h-86v-120.55c0-20.321 15.243-37.174 35.529-39.208.101-.028 49.426-7.485 73.87-31.93l22.462-22.452c5.724-5.724 13.913-7.166 20.797-4.726h.001c.003.001.006.003.009.004 7.975 2.819 13.332 10.399 13.332 18.862 0 5.338-2.082 10.359-5.861 14.14l-51.21 51.22c-3.905 3.906-3.904 10.237.001 14.142 3.904 3.904 10.237 3.905 14.142-.001l51.21-51.22c7.556-7.557 11.718-17.601 11.718-28.281 0-14.439-7.804-27.587-20-34.639v-85.361c0-11.028 8.972-20 20-20s20 8.972 20 20v135.84c0 18.296-4.617 36.46-13.353 52.528-2.639 4.852-.844 10.924 4.009 13.562 4.851 2.637 10.925.843 13.562-4.009 10.325-18.99 15.782-40.457 15.782-62.081v-135.84c0-22.056-17.944-40-40-40z"
      />
    </svg>
  );
};

export default Sapling;
