import React from 'react';
import { graphql } from 'gatsby';
import useMedia from 'use-media';

import Work from '../svgs/Work';
import Motivation from '../svgs/Motivation';
import Sapling from '../svgs/Sapling';
import United from '../svgs/United';
import Responsibility from '../svgs/Responsibility';
import Diversity from '../svgs/Diversity';

import { ImageTextRow } from '../components/ImageTextRow/';
import PageLayout from '../components/PageLayout/';
import SEO from '../components/seo';
import { Badges } from '../components/Badges/';
import { Hero } from '../components/Hero/';
import Section from '../components/Section/';
import { ArticleList } from '../components/ArticleList/';
import Advantage, { AdvantageRow } from '../components/Advantage/';
import {
  Testimonial,
  Container,
  Title,
  CTAContainer,
  CTAContent,
  CTAHeader,
  CTASubTitle,
  CTAWrapper,
  LinkTo,
} from '../styles/wir-stellen-ein';
import { GatsbyImage } from 'gatsby-plugin-image';

const CareerPage = ({ data: { Postbote, Mitarbeiter1, Mitarbeiter2, Mitarbeiter3, Action2 } }) => {
  const isMobile = useMedia({ maxWidth: '767px' });

  return (
    <PageLayout>
      <SEO title="Deine Karriere bei Uns!" />
      <Hero
        image={Postbote}
        title="Deine Karriere bei Uns!"
        subTitle="Werde jetzt Teil des DropFriends-Teams."
        fullscreen>
        <div className="font-display font-xl tracking-wide hidden lg:block">
          Jetzt die DropFriends-App downloaden.
        </div>
        <Badges />
      </Hero>
      <Section fullWidth>
        <ImageTextRow leftImageFluid={Mitarbeiter1} position="left" fullWidth>
          <Testimonial>
            „Das Volumen an Learnings und Erfahrungen, das ich in diesen 3 Monaten gesammelt habe,
            wird mich mein ganzes Leben begleiten und ich werde immer zurückblicken auf die tolle
            Zeit bei dem Kölner StartUp DropFriends.“
          </Testimonial>
          <p>- Leonardo, ehem. Performance Marketing & Social Media</p>
        </ImageTextRow>
        <ImageTextRow rightImageFluid={Mitarbeiter2} position="right" fullWidth>
          <Testimonial>
            „Zusammenfassend kann man sagen, dass mein Praktikum mich in vielen Aspekten
            weitergebracht hat. Ich habe neben den Hard Skills auch zusätzliche Soft Skills, wie
            z.B. Überzeugungskraft und Zeitmanagement gelernt. Deshalb freue ich mich auf eine
            weiterhin gute und produktive Zeit bei DropFriends.“
          </Testimonial>
          <p>- Christel, Sales Managerin</p>
        </ImageTextRow>
        <ImageTextRow leftImageFluid={Mitarbeiter3} position="left" fullWidth>
          <Testimonial>
            „Die Zeit bei DropFriends war sehr ereignis- und lehrreich, wir haben als Team viel
            gelacht sowie gemeinsam Hürden überwunden.“
          </Testimonial>
          <p>- Larissa, ehem. Content Creation</p>
        </ImageTextRow>
      </Section>

      <ArticleList
        title="Arbeiten bei DropFriends"
        titleId="blog"
        articles={[
          {
            title: 'Larissas Praktikumsrückblick',
            link: 'https://blog.dropfriends.com/2021/05/14/larissas-praktikumsrueckblick/',
            image: 'https://blog.dropfriends.com/wp-content/uploads/2021/05/larissa.jpg',
            description:
              '3 Monate sind nun schon vorbei und jetzt heißt es Abschied nehmen. Die Zeit bei DropFriends war sehr ereignis- und lehrreich, wir haben als Team viel gelacht sowie gemeinsam Hürden überwunden.',
          },
          {
            title: 'Warum wir an das Homeoffice glauben',
            link: 'https://blog.dropfriends.com/2020/07/15/warum-wir-an-das-homeoffice-glauben/',
            image:
              'https://blog.dropfriends.com/wp-content/uploads/2020/10/yasmin_martin_osamah_small.jpg',
            description:
              'DropFriends ist ein sogenanntes Remote-Startup. Das bedeutet, dass wir unsere Arbeit ohne Büroräume ausüben. Darüber war sich das Gründer-Team bereits vor Gründung und vor Corona-Ausbruch in Deutschland einig.',
          },
          {
            title: 'Retrospektive',
            link: 'https://blog.dropfriends.com/2021/03/12/retrospektive/',
            image: 'https://blog.dropfriends.com/wp-content/uploads/2021/03/gruppenbild.jpg',
            description:
              '„Seid Ihr bereit für eine Runde „Mad Sad Glad?“ Was sich im ersten Moment wie die Ankündigung eines familiären Spieleabends anhört, hat bei DropFriends eine ganz andere Bedeutung.',
          },
          {
            title: 'Mehr als nur ein Job',
            link: 'https://blog.dropfriends.com/2020/08/19/mein-dropfriends-praktikum/',
            image: 'https://blog.dropfriends.com/wp-content/uploads/2020/08/IMG_1919.png',
            description:
              'Das Ende meiner Zeit bei DropFriends ist gekommen und so möchte ich diesen Blog mit einem Rückblick auf das Gelernte, Erlebte und Erfahrene abschließen.',
          },
        ]}
      />

      <AdvantageRow title="Die DropFriends-Vorteile" titleId="vorteile">
        <Advantage
          icon={<Work className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          title="Agilität beim Arbeiten">
          <p>
            Gestalte Deine Arbeitszeiten selbst für Dein Homeoffice und Deine persönlichen
            Bedürfnisse. Dabei ist es egal, ob Du von Paris, Dubai oder Köln aus arbeitest. Als
            Digital Nomad sind Dir keine Grenzen gesetzt.
          </p>
        </Advantage>

        <Advantage
          icon={<United className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          title="Flache Hierarchie">
          <p>
            Du bist ein echter Teil des Teams und bist mit den Gründern auf Augenhöhe. Wir sind
            davon überzeugt, dass wir nur dann erfolgreich sein können, wenn auch jedes Teammitglied
            glücklich ist. Deswegen haben wir auch stets ein offenes Ohr, wenn Du es brauchst.
          </p>
        </Advantage>

        <Advantage
          icon={<Responsibility className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          title="Eigenverantwortliches Arbeiten">
          <p>
            Du erhältst die Möglichkeit, Verantwortung zu übernehmen und Dich persönlich
            weiterzuentwickeln. Genug Zeit zum Schmunzeln, Lachen und auch Lernen bekommst Du
            natürlich auch.
          </p>
        </Advantage>

        <Advantage
          icon={<Sapling className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          title="Gemeinsam für eine bessere Zukunft">
          <p>
            Du leistest Tag für Tag einen Beitrag dazu, den CO2-Ausstoß zu reduzieren, Menschen
            miteinander zu verbinden und am Großprojekt Smart City mitzuwirken.
          </p>
        </Advantage>

        <Advantage
          icon={<Motivation className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          title="Tolle Benefits">
          <p>
            Dir werden 1:1 Videocalls mit externen Professionals & Mentoren geboten. Zudem kommen
            noch Überraschungspakete und zusätzlich fixe Urlaubstage: Star Wars Day, Zuckerfest oder
            Heilig Abend, Weiberfastnacht und Rosenmontag.
          </p>
        </Advantage>

        <Advantage
          icon={<Diversity className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          title="Diversität ist uns wichtig">
          <p>
            Wir sind ein nerdig diverses Team, das bei Videokonferenzen gerne Jogginghosen mit
            Hemden und Blusen kombiniert. Professionalität ist eine Sache des Mindsets! Vergrößere
            jetzt unser Team und mach es noch bunter!
          </p>
        </Advantage>
      </AdvantageRow>

      <CTAContainer>
        <CTAWrapper>
          <div className="container mx-auto md:flex md:items-center md:px-20">
            <div className="md:shrink-0 md:w-[50%] mb-8 md:mb-0">
              <GatsbyImage
                className="lg:w-7/12 bg-no-repeat	"
                objectFit="contain"
                objectPosition="center bottom"
                alt=""
                image={Action2.childImageSharp.gatsbyImageData}
              />
            </div>
            <CTAContent>
              <CTAHeader>Mache Jetzt den nächsten Schritt in Deiner Entwicklerkarriere!</CTAHeader>
              <CTASubTitle>
                Mit dem DropFriends-Bootcamp helfen wir Dir wichtige Arbeitserfahrung zu sammeln
              </CTASubTitle>
              <LinkTo to="/junior-bootcamp">Mehr Infos</LinkTo>
            </CTAContent>
          </div>
        </CTAWrapper>
      </CTAContainer>
      {/* <Container>
        <div className=" mx-auto">
          <Title>Unsere Stellenangebote</Title>
          <iframe
            title="Jobs bei DropFriends"
            src="https://www.catch-talents.de/api/embed.php?id=SGFsbG8gV2VsdCEgMjIx&embed=1"
            frameBorder="0"
            height={isMobile ? '1300px' : '900px'}
            width="100%"
          />
        </div>
      </Container> */}
    </PageLayout>
  );
};

export const query = graphql`
  {
    Postbote: file(relativePath: { eq: "Career.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Mitarbeiter1: file(relativePath: { eq: "Mitarbeiter1.jpeg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Mitarbeiter2: file(relativePath: { eq: "Mitarbeiter2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Mitarbeiter3: file(relativePath: { eq: "Mitarbeiter3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Action1: file(relativePath: { eq: "bootcamp/action-1.png" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Action2: file(relativePath: { eq: "bootcamp/action-2.png" }) {
      childImageSharp {
        gatsbyImageData(sizes: "90vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default CareerPage;
